import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Englight English Academy
			</title>
			<meta name={"description"} content={"Ваш шлях до оволодіння англійською мовою."} />
			<meta property={"og:title"} content={"Englight English Academy"} />
			<meta property={"og:description"} content={"Ваш шлях до оволодіння англійською мовою."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-1.jpg?v=2024-05-30T08:45:54.394Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-1.jpg?v=2024-05-30T08:45:54.394Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-1.jpg?v=2024-05-30T08:45:54.394Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-1.jpg?v=2024-05-30T08:45:54.394Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-1.jpg?v=2024-05-30T08:45:54.394Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-1.jpg?v=2024-05-30T08:45:54.394Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-1.jpg?v=2024-05-30T08:45:54.394Z"} />
		</Helmet>
		<Components.Header111>
			<Override slot="text" />
		</Components.Header111>
		<Section padding="50px 0 50px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Розширте свої горизонти
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				В Englight English Academy ми пропонуємо повний набір курсів англійської мови, розроблених для задоволення різноманітних навчальних потреб і цілей. Наша навчальна програма побудована таким чином, щоб кожен студент міг знайти шлях, який відповідає його темпу навчання та цілям. Пориньте у світ англійської мови разом з нами та досліджуйте різноманітні пропозиції курсів, які роблять нашу академію унікальною.
			</Text>
		</Section>
		<Section padding="50px 0 50px 0" quarkly-title="List-2" background="--color-lightD1">
			<Text as="h2" font="--headline2" md-font="--headline2" margin="0 0 2rem 0">
				Загальна англійська
			</Text>
			<Box
				min-width="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Grammar Essentials
					</Text>
				</Box>
				<Box min-width="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Збільшення словникового запасу
					</Text>
				</Box>
				<Box min-width="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Практична англійська
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 50px 0" quarkly-title="List-2" background="--color-light">
			<Text as="h2" font="--headline2" md-font="--headline2" margin="0 0 2rem 0">
				Ділова англійська
			</Text>
			<Box
				min-width="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Професійна англійська
					</Text>
				</Box>
				<Box min-width="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Навички презентації
					</Text>
				</Box>
				<Box min-width="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Мова переговорів
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 50px 0" quarkly-title="List-2" background="--color-lightD1">
			<Text as="h2" font="--headline2" md-font="--headline2" margin="0 0 2rem 0">
				Підготовка до іспиту
			</Text>
			<Box
				min-width="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Стратегії складання іспитів
					</Text>
				</Box>
				<Box min-width="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Тренувальні тести
					</Text>
				</Box>
				<Box min-width="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Сеанси зворотного зв’язку
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 50px 0" quarkly-title="List-2" background="--color-light">
			<Text as="h2" font="--headline2" md-font="--headline2" margin="0 0 2rem 0">
				Англійська для спеціальних цілей (ESP)
			</Text>
			<Box
				min-width="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Медична англійська
					</Text>
				</Box>
				<Box min-width="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Юридична англійська
					</Text>
				</Box>
				<Box min-width="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Англійська для туризму
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65ba3ab38e2e8e002177087f"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});